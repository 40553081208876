import merge from 'lodash/merge';
import { takeLatest, call, put, select } from 'redux-saga/effects';
import Sentry from 'app/lib/analytics/sentry';
import ERRORS from 'app/lib/analytics/sentry/errors';

import getContent from './service/getContent';
import * as actions from './actions';
import {
  GET_CONTENT_PAGE_REQUEST,
  GET_CONTENT_ASSET_COLLECTION_REQUEST,
  contentfulTypes,
  selectors,
} from './constants';

export function* getContentRequest(action, type) {
  try {
    const contentStore = yield select(selectors.pages);
    const pageCache = contentStore[action.slug];

    if (!pageCache || !pageCache.content) {
      const content = yield call(getContent, type, action.slug);

      if (type === contentfulTypes.ASSET_COLLECTION) {
        yield put(actions.fetchAssetCollectionSuccess(content));
      } else {
        yield put(actions.fetchPageSuccess(content));
      }
    } else if (type === contentfulTypes.ASSET_COLLECTION) {
      yield put(
        actions.fetchAssetCollectionSuccess({
          assets: pageCache.content,
          slug: action.slug,
        })
      );
    } else {
      yield put(
        actions.fetchPageSuccess({
          title: pageCache.title,
          slug: action.slug,
          body: pageCache.content,
        })
      );
    }
  } catch (error) {
    Sentry.log(
      error,
      merge(ERRORS.GET_CONTENT_PAGE_OR_ASSET, {
        extra: {
          type,
          slug: action ? action.slug : 'no slug',
        },
      })
    );
    yield put(actions.fetchPageFailure(action.slug, error));
  }
}

function* getPageRequest(action) {
  yield getContentRequest(action, contentfulTypes.PAGE);
}

function* getAssetCollectionRequest(action) {
  yield getContentRequest(action, contentfulTypes.ASSET_COLLECTION);
}

export function* combinedSagas() {
  yield takeLatest(GET_CONTENT_PAGE_REQUEST, getPageRequest);

  yield takeLatest(
    GET_CONTENT_ASSET_COLLECTION_REQUEST,
    getAssetCollectionRequest
  );
}
