export const LANDING_GET_QUOTE_REQUEST = 'LANDING_GET_QUOTE_REQUEST';
export const LANDING_GET_QUOTE_SUCCESS = 'LANDING_GET_QUOTE_SUCCESS';
export const LANDING_GET_QUOTE_FAILURE = 'LANDING_GET_QUOTE_FAILURE';

export const LANDING_SET_PROPERTY_OWNER = 'LANDING_SET_PROPERTY_OWNER';
export const LANDING_GET_ADDRESS_LIST_REQUEST =
  'LANDING_GET_ADDRESS_LIST_REQUEST';
export const LANDING_GET_ADDRESS_LIST_SUCCESS =
  'LANDING_GET_ADDRESS_LIST_SUCCESS';
export const LANDING_GET_ADDRESS_LIST_FAILURE =
  'LANDING_GET_ADDRESS_LIST_FAILURE';

export const SET_ADDRESS_EXISTS_DIALOG_OPEN = 'SET_ADDRESS_EXISTS_DIALOG_OPEN';
export const ON_ADDRESS_EXISTS_DIALOG_CONTINUE =
  'ON_ADDRESS_EXISTS_DIALOG_CONTINUE';

export const JOURNEY_START_NO_BUNDLES = 'JOURNEY_START_NO_BUNDLES';
export const JOURNEY_START_PRESELECT = 'JOURNEY_START_PRESELECT';
