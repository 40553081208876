import {
  GET_QUOTES_REQUEST,
  SHOW_TIL_DIALOG,
  HIDE_TIL_DIALOG,
  GET_QUOTES_FAILURE,
  ACCEPT_QUOTES_REQUEST,
  ACCEPT_QUOTES_SUCCESS,
  ACCEPT_QUOTES_FAILURE,
  CONFIRM_QUOTE,
  SET_CURRENT_TAB,
  TOGGLE_ENERGY_QUOTE_DISCLAIMER_READ,
  SET_OFFERED_QUOTES,
  PRESELECT_SUCCESS,
  EV_INTEREST_CHECKED,
} from './constants';

export const showTilDialog = (tariff) => ({ type: SHOW_TIL_DIALOG, tariff });
export const hideTilDialog = () => ({ type: HIDE_TIL_DIALOG });
export const acceptQuotes = (quoteIds, evTariffInterest) => ({
  type: ACCEPT_QUOTES_REQUEST,
  quoteIds,
  evTariffInterest,
});
export const getQuotesFailure = (error) => ({
  type: GET_QUOTES_FAILURE,
  error,
});
export const acceptQuotesSuccess = (response) => ({
  type: ACCEPT_QUOTES_SUCCESS,
  quotes: response,
});

export const confirmQuote = (quote, map = false) => ({
  type: CONFIRM_QUOTE,
  quote,
  map,
});

export const setOfferedQuotes = (offered) => ({
  type: SET_OFFERED_QUOTES,
  offered,
});

export const acceptQuotesFailure = (error) => ({
  type: ACCEPT_QUOTES_FAILURE,
  error,
});

export const setCurrentTab = (currentTab) => ({
  type: SET_CURRENT_TAB,
  currentTab,
});

export const getQuotes = (paymentPlan, selectedServices, quoteType) => ({
  type: GET_QUOTES_REQUEST,
  paymentPlan,
  selectedServices,
  quoteType,
});

export const toggleDisclaimerRead = (toggle) => ({
  type: TOGGLE_ENERGY_QUOTE_DISCLAIMER_READ,
  disclaimerRead: toggle,
});

export const preselectSuccess = (preselectState) => ({
  type: PRESELECT_SUCCESS,
  preselectState,
});

export const setEvInterestChecked = (checked) => ({
  type: EV_INTEREST_CHECKED,
  checked,
});
