export const STORE_NAME = 'EnergyProductSelectionForm';

export const PRODUCT_ELECTRICITY_AND_GAS = 'dual';
export const PRODUCT_ELECTRICITY = 'electricity';
export const PRODUCT_GAS = 'gas';

export const METER_STANDARD = 'standard';
export const METER_ECONOMY7 = 'economy';

export const PAYMENT_TYPE_PREPAID = 'prepaid';
export const PAYMENT_TYPE_DIRECT_DEBIT = 'directDebit';

export const PREPAID_ELECTRICITY_AND_GAS = 'dual';
export const PREPAID_ELECTRICITY = 'electricity';
export const PREPAID_GAS = 'gas';

export const ENERGY_USE_LOW = 'low';
export const ENERGY_USE_MEDIUM = 'medium';
export const ENERGY_USE_HIGH = 'high';
export const ENERGY_USE_CUSTOM = 'custom';
export const ENERGY_USE_EAC = 'eac';

export const GAS_USE_LOW_VALUE = 7500;
export const ENERGY_USE_LOW_VALUE = 1800;
export const ENERGY_USE_LOW_DAY_VALUE = 1276; // 58% of 2200
export const ENERGY_USE_LOW_NIGHT_VALUE = 924; // 42% of 2200

export const GAS_USE_MEDIUM_VALUE = 11500;
export const ENERGY_USE_MEDIUM_VALUE = 2700;
export const ENERGY_USE_MEDIUM_DAY_VALUE = 2262; // 58% of 3900
export const ENERGY_USE_MEDIUM_NIGHT_VALUE = 1638; // 42% of 3900

export const GAS_USE_HIGH_VALUE = 17000;
export const ENERGY_USE_HIGH_VALUE = 4100;
export const ENERGY_USE_HIGH_DAY_VALUE = 3886; // 58% of 6700
export const ENERGY_USE_HIGH_NIGHT_VALUE = 2814; // 42% of 6700

export const ELECTRICITY_CONSUMPTION = 'electricityConsumption';
export const ELECTRICITY_DAY_CONSUMPTION = 'electricityDayConsumption';
export const ELECTRICITY_NIGHT_CONSUMPTION = 'electricityNightConsumption';
export const GAS_CONSUMPTION = 'gasConsumption';

export const PAYMENT_PLAN_BUDGET = 'budget';
export const PAYMENT_PLAN_ARREARS = 'arrears';

export const TARIFF_TYPE_FIXED = 'fixed';
export const TARIFF_TYPE_VARIABLE = 'variable';

export const ENERGY_PRODUCT_SELECTION_FORM_UPDATE =
  'ENERGY_PRODUCT_SELECTION_FORM_UPDATE';
export const ENERGY_PRODUCT_SELECTION_FORM_VALIDATE =
  'ENERGY_PRODUCT_SELECTION_FORM_VALIDATE';

export const POST_APPLICATION_PROPERTY_METER_FAILURE =
  'POST_APPLICATION_PROPERTY_METER_FAILURE';
export const POST_APPLICATION_PROPERTY_METER_REQUEST =
  'POST_APPLICATION_PROPERTY_METER_REQUEST';
export const POST_APPLICATION_PROPERTY_METER_SUCCESS =
  'POST_APPLICATION_PROPERTY_METER_SUCCESS';

export const PRESELECT_SUCCESS =
  'ENERGY_PRODUCT_SELECTION_FORM_PRESELECT_SUCCESS';
