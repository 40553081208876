import { call, put, select, takeLatest } from 'redux-saga/effects';
import { openErrorGlobalDialog } from 'redux/modules/GlobalDialog/actions';
import {
  putApplicationPropertySupplyElectricity,
  putApplicationPropertySupplyGas,
} from './service/putApplicationPropertyMeter';
import { EntryFormApi } from 'redux/modules/EntryForm/api';
import quoteApi from 'redux/modules/Energy/Quote/api';
import { getQuotes, setCurrentTab } from 'redux/modules/Energy/Quote/actions';
import { ServiceSelectionApi } from 'app/redux/modules/ServiceSelection/api';
import Sentry from 'app/lib/analytics/sentry';
import ERRORS from 'app/lib/analytics/sentry/errors';
import { getClient } from 'app/lib/analytics';
import { EVENTS } from 'app/lib/analytics/constants';
import analyticsSelectors from 'app/lib/analytics/AnalyticsProvider/selectors';

import * as actions from './actions';
import { EnergyProductSelectionApi } from './api';
import {
  POST_APPLICATION_PROPERTY_METER_REQUEST,
  POST_APPLICATION_PROPERTY_METER_SUCCESS,
  PRODUCT_ELECTRICITY,
  PRODUCT_ELECTRICITY_AND_GAS,
  PRODUCT_GAS,
  TARIFF_TYPE_FIXED,
  TARIFF_TYPE_VARIABLE,
} from './constants';

import { ENERGY_TARIFF_DEFAULT } from 'config';

function electricityRequest(action) {
  return call(
    putApplicationPropertySupplyElectricity,
    action.electricity.consumption,
    action.electricity.nightConsumption,
    action.electricity.prepayment,
    !!action.electricity.economy
  );
}

function gasRequest(action) {
  return call(
    putApplicationPropertySupplyGas,
    action.gas.consumption,
    action.gas.prepayment
  );
}

function* handlePostApplicationPropertyMeter(action) {
  const { client: analytics } = getClient();

  try {
    switch (action.product) {
      case PRODUCT_ELECTRICITY_AND_GAS:
        yield electricityRequest(action);
        yield gasRequest(action);
        break;

      case PRODUCT_ELECTRICITY:
        yield electricityRequest(action);
        break;

      case PRODUCT_GAS:
        yield gasRequest(action);
        break;
    }

    const quoteRequestedState = yield select(
      analyticsSelectors[EVENTS.ENERGY_QUOTE_REQUESTED]
    );
    analytics.track(EVENTS.ENERGY_QUOTE_REQUESTED, quoteRequestedState);
    yield put(actions.postApplicationPropertyMeterSuccess());
  } catch (error) {
    Sentry.log(error, ERRORS.UPDATE_APP_PROPERTY_METER);
    yield put(actions.postApplicationPropertyMeterFailure(error));
    const message = `Sorry we encountered an error while processing your property meters information. ${error}`;
    yield put(openErrorGlobalDialog(message));
  }
}

function* triggerFetchQuotes() {
  const [
    paymentPlan,
    eligibleForTariffChange,
    selectedServices,
    isHomeOwner,
  ] = yield select((state) => [
    EnergyProductSelectionApi.getPaymentPlan(state),
    ServiceSelectionApi.isEligibleForTariffChange(state),
    ServiceSelectionApi.getSelectedServices(state),
    EntryFormApi.isHomeOwner(state),
  ]);

  const currentTariffType = yield select(quoteApi.getCurrentTariffTab);

  let tariffType;
  if (currentTariffType) {
    tariffType = currentTariffType;
  } else {
    if (ENERGY_TARIFF_DEFAULT) {
      tariffType = ENERGY_TARIFF_DEFAULT;
    } else {
      tariffType =
        eligibleForTariffChange && !isHomeOwner
          ? TARIFF_TYPE_VARIABLE
          : TARIFF_TYPE_FIXED;
    }

    yield put(setCurrentTab(String(tariffType).toUpperCase()));
  }

  yield put(getQuotes(paymentPlan, selectedServices, tariffType.toLowerCase()));
}

export function* combinedSagas() {
  yield takeLatest(
    POST_APPLICATION_PROPERTY_METER_REQUEST,
    handlePostApplicationPropertyMeter
  );
  yield takeLatest(POST_APPLICATION_PROPERTY_METER_SUCCESS, triggerFetchQuotes);
}
