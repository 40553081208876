import { connector } from 'redux/utils/connector';
import applicationCheck from 'redux/modules/ApplicationCheck';
import ProductSelectionForm from 'components/modules/Energy/ProductSelectionForm';
import EnergyQuoteApi from 'redux/modules/Energy/Quote/api';
import { ApplicationApi } from 'redux/modules/App/api';
import { EntryFormApi } from 'redux/modules/EntryForm/api';
import { EnergyProductSelectionApi } from 'redux/modules/Energy/ProductSelectionForm/api';
import { FeatureFlagsApi } from 'redux/modules/FeatureFlags/api';
import { FLAGS } from 'redux/modules/FeatureFlags/constants';

import { STORE_NAME } from './constants';

const isEACEnabled = (state) => {
  // enable for everyone
  if (FeatureFlagsApi.getFlag(state, FLAGS.EAC_EVERYONE)) return true;

  // enable for sales
  if (
    FeatureFlagsApi.getFlag(state, FLAGS.EAC_SALES) &&
    ApplicationApi.isSalesApplication(state)
  )
    return true;

  return false;
};

const mapStateToProps = (state) => ({
  isRequestInProgress:
    EnergyProductSelectionApi.isRequestInProgress(state) ||
    EnergyQuoteApi.isRequestInProgress(state),
  isEACEnabled: isEACEnabled(state),
  eacValues: EntryFormApi.getEac(state),
});

export default connector(
  STORE_NAME,
  mapStateToProps
)(applicationCheck(ProductSelectionForm));
