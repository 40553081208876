import {
  ENERGY_PRODUCT_SELECTION_FORM_UPDATE,
  ENERGY_PRODUCT_SELECTION_FORM_VALIDATE,
  POST_APPLICATION_PROPERTY_METER_FAILURE,
  POST_APPLICATION_PROPERTY_METER_REQUEST,
  POST_APPLICATION_PROPERTY_METER_SUCCESS,
  PRESELECT_SUCCESS,
} from './constants';
import validation from './validation';

export const updateField = (name, value) => ({
  type: ENERGY_PRODUCT_SELECTION_FORM_UPDATE,
  name,
  value,
});

export const validateForm = (fields) => {
  const { fieldErrors, isValidForm } = validation(fields);

  return {
    type: ENERGY_PRODUCT_SELECTION_FORM_VALIDATE,
    fieldErrors,
    isValidForm,
  };
};

export const postApplicationPropertyMeterRequest = (payload) => {
  const data = {
    type: POST_APPLICATION_PROPERTY_METER_REQUEST,
    ...payload,
  };

  return data;
};

export const postApplicationPropertyMeterSuccess = (response) => ({
  type: POST_APPLICATION_PROPERTY_METER_SUCCESS,
  response,
});

export const postApplicationPropertyMeterFailure = (error) => ({
  type: POST_APPLICATION_PROPERTY_METER_FAILURE,
  error,
});

export const preselectSuccess = (preselectState) => ({
  type: PRESELECT_SUCCESS,
  preselectState,
});
