import { call, put, select, takeLatest } from 'redux-saga/effects';
import { openErrorGlobalDialog } from 'redux/modules/GlobalDialog/actions';
import Sentry from 'app/lib/analytics/sentry';
import ERRORS from 'app/lib/analytics/sentry/errors';
import { submitProviderDetails } from 'redux/modules/HomeAudit/actions';
import { SERVICES } from 'redux/modules/HomeAudit/constants';

import * as actions from './actions';
import acceptEnergyQuotes from './service/putAcceptEnergyQuotes';
import {
  PRODUCT_ELECTRICITY,
  PRODUCT_ELECTRICITY_AND_GAS,
  PRODUCT_GAS,
} from '../ProductSelectionForm/constants';
import { EnergyProductSelectionApi } from '../ProductSelectionForm/api';
import generateEnergyQuotes, {
  QUOTE_TYPE_DUAL,
  QUOTE_TYPE_ELECTRICITY,
  QUOTE_TYPE_GAS,
} from './service/postGenerateEnergyQuotes';
import { ACCEPT_QUOTES_REQUEST, GET_QUOTES_REQUEST } from './constants';

const serviceToQuoteMap = {
  gas: QUOTE_TYPE_GAS,
  electricity: QUOTE_TYPE_ELECTRICITY,
  dual: QUOTE_TYPE_DUAL,
};

function getSelectedServiceQuoteType() {
  const service = window.store.getState().toJS().EnergyProductSelectionForm
    .fields.energy;
  const quoteType = serviceToQuoteMap[service];
  if (!quoteType) throw new Error(`Unrecognised service (${service})`);
  return quoteType;
}

// getQuote on energy/selection
export function* handleGetQuotesRequest(action) {
  yield put(actions.setCurrentTab(action.quoteType.toUpperCase()));
  try {
    const { data } = yield call(
      generateEnergyQuotes,
      getSelectedServiceQuoteType(),
      action.paymentPlan,
      action.selectedServices,
      action.quoteType
    );

    yield put(actions.setOfferedQuotes(data.offered));
    yield put(actions.confirmQuote(data.current, true));

    const energySelection = yield select(
      EnergyProductSelectionApi.getEnergySelection
    );

    if (energySelection === PRODUCT_ELECTRICITY_AND_GAS) {
      yield put(submitProviderDetails(SERVICES.GAS_ELEC));
    }

    if (energySelection === PRODUCT_GAS) {
      yield put(submitProviderDetails(SERVICES.GAS));
    }
    if (energySelection === PRODUCT_ELECTRICITY) {
      yield put(submitProviderDetails(SERVICES.ELECTRICITY));
    }
  } catch (error) {
    Sentry.log(error, ERRORS.UPDATE_ENERGY_QUOTE);
    yield put(actions.getQuotesFailure(error));
    const errorMessage = `We encountered an error while sending the quote request. ${error}`;
    yield put(openErrorGlobalDialog(errorMessage));
  }
}

export function* handleAcceptQuotes(action) {
  try {
    const response = yield call(
      acceptEnergyQuotes,
      action.quoteIds,
      action.evTariffInterest
    );
    yield put(actions.acceptQuotesSuccess(response));
  } catch (error) {
    Sentry.log(error, ERRORS.UPDATE_ENERGY_ACCEPT_QUOTE);
    yield put(actions.acceptQuotesFailure(error));
    const errorMessage = `We encountered an error while saving the quote. ${error}`;
    yield put(openErrorGlobalDialog(errorMessage));
  }
}

export function* combinedSagas() {
  yield takeLatest(GET_QUOTES_REQUEST, handleGetQuotesRequest);
  yield takeLatest(ACCEPT_QUOTES_REQUEST, handleAcceptQuotes);
}
