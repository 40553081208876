import { connector } from 'redux/utils/connector';
import { STORE_NAME } from 'redux/modules/Energy/Quote/constants';
import applicationCheck from 'redux/modules/ApplicationCheck';
import { EntryFormApi } from 'redux/modules/EntryForm/api';
import EnergySelectionUi from 'components/pages/EnergySelection';

const EnergySelectionContainer = connector(STORE_NAME, (state) => ({
  isValidEntryForm: EntryFormApi.isValidEntryForm(state),
}))(EnergySelectionUi);

export default applicationCheck(EnergySelectionContainer);
