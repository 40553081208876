import { call, put, select, takeLatest } from 'redux-saga/effects';
import browserHistory from 'components/root/browserHistory';
import * as EntryFormActions from 'redux/modules/EntryForm/actions';
import { handlePostAddress } from 'redux/modules/EntryForm/saga';
import { BundlesPage } from 'redux/modules/Routes/constants';
import { ServiceSelectionApi } from 'redux/modules/ServiceSelection/api';
import { propositionFetch } from 'redux/modules/Bundles/actions';
import { setServices } from 'redux/modules/ServiceSelection/saga';
import * as actions from './actions';
import * as types from './types';
import constructSagas from 'redux/modules/AddressPickerNew/saga';
import { getIsPreselectJourney } from 'redux/modules/Preselect/Parameters/api';

function* handleGetQuote({ address, isPropertyOwner }) {
  try {
    const occupancy = { type: isPropertyOwner ? 'OWNERSHIP' : 'TENANCY' };
    if (isPropertyOwner) {
      occupancy.ownership = {};
    } else {
      occupancy.tenancy = {};
    }
    const data = yield call(handlePostAddress, {
      address,
      next: () => {},
      occupancy,
    });
    yield put(actions.getQuoteSuccess());

    // TODO use booleans
    const propertyOwnerString = isPropertyOwner ? 'yes' : 'no';
    yield put(EntryFormActions.update(address, 'currentAddressSelected'));
    yield put(
      EntryFormActions.update(
        propertyOwnerString,
        'currentAddressOwnThisProperty'
      )
    );

    const preselect = yield select(ServiceSelectionApi.getServicePreselect);
    const preselectJourney = yield select(getIsPreselectJourney);

    if (data?.isAlreadyRegistered) {
      yield put(actions.setAddressExistsDialogOpen(true));
    } else if (preselectJourney) {
      yield put(actions.journeyStartPreselect());
    } else if (preselect.active) {
      yield call(setServices, preselect.services);
      yield put(propositionFetch());
      yield put(actions.journeyStartNoBundles());
    } else {
      handleContinue();
    }
  } catch (err) {
    yield put(actions.getQuoteFailure());
  }
}

function handleContinue() {
  browserHistory.push(BundlesPage);
}

export function* combinedSagas() {
  yield takeLatest(types.LANDING_GET_QUOTE_REQUEST, handleGetQuote);
  yield takeLatest(types.ON_ADDRESS_EXISTS_DIALOG_CONTINUE, handleContinue);
  const addressPickerSagas = constructSagas('LANDING');
  yield call(addressPickerSagas);
}
