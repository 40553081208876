import createAddressPickerActions from 'redux/modules/AddressPickerNew/actions';
import * as types from './types';

export const getQuote = (address, isPropertyOwner) => ({
  type: types.LANDING_GET_QUOTE_REQUEST,
  address,
  isPropertyOwner,
});

export const getQuoteSuccess = () => ({
  type: types.LANDING_GET_QUOTE_SUCCESS,
});

export const getQuoteFailure = (error) => ({
  type: types.LANDING_GET_QUOTE_FAILURE,
  error,
});

export const setIsPropertyOwner = (isPropertyOwner) => ({
  type: types.LANDING_SET_PROPERTY_OWNER,
  isPropertyOwner,
});

export const getAddressList = (postcode) => ({
  type: types.LANDING_GET_ADDRESS_LIST_REQUEST,
  postcode,
});

export const getAddressListSuccess = (addresses) => ({
  type: types.LANDING_GET_ADDRESS_LIST_SUCCESS,
  addresses,
});

export const getAddressListFailure = (error) => ({
  type: types.LANDING_GET_ADDRESS_LIST_FAILURE,
  error,
});

export const setAddressExistsDialogOpen = () => ({
  type: types.SET_ADDRESS_EXISTS_DIALOG_OPEN,
});

export const onAddressExistsDialogContinue = () => ({
  type: types.ON_ADDRESS_EXISTS_DIALOG_CONTINUE,
});

export const journeyStartNoBundles = () => ({
  type: types.JOURNEY_START_NO_BUNDLES,
});

export const journeyStartPreselect = () => ({
  type: types.JOURNEY_START_PRESELECT,
});

export const addressPickerActions = createAddressPickerActions('LANDING');
