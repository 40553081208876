import isRequestInProgress from 'redux/utils/isRequestInProgress';
import { STORE_NAME, TARIFF_TYPES } from './constants';
import { mapCurrentQuote } from './presentation';

class EnergyQuoteApi {
  static getCurrentQuote(state) {
    return state.get(STORE_NAME).toJS().current;
  }

  static getOfferedQuote(state) {
    const currentQuote = this.getCurrentQuote(state);
    const offeredQuotes = state.get(STORE_NAME).toJS().offered;
    const mappedQuotes = offeredQuotes.map(mapCurrentQuote);

    return (
      mappedQuotes.find(
        (quote) => quote.tariffType === currentQuote?.tariffType || ''
      ) ?? {}
    );
  }

  static isRequestInProgress(state) {
    return isRequestInProgress(state, STORE_NAME);
  }

  static getOriginalElectricityConsumptionFromQuote(state) {
    const afterAdjustmentConsumption = EnergyQuoteApi.getCurrentQuote(state)
      .electricity.rawAnnualConsumption;
    const assumedReduction = EnergyQuoteApi.getAssumedElectricityConsumptionSavingRate(
      state
    );
    const adjustedPc = (1 - assumedReduction) * 100;
    return (afterAdjustmentConsumption / adjustedPc) * 100;
  }

  static getAssumedElectricityConsumptionSavingRate(state) {
    return (
      EnergyQuoteApi.getCurrentQuote(state).electricity
        .assumedConsumptionSavingRate || 0
    );
  }

  static isCurrentTariffFixed(state) {
    return (
      EnergyQuoteApi.getCurrentQuote(state).tariffType === TARIFF_TYPES.FIXED
    );
  }

  static hasCurrentGasPrepaymentTariff(state) {
    return (
      EnergyQuoteApi.getCurrentQuote(state).gas &&
      EnergyQuoteApi.getCurrentQuote(state).gas.tariff.paymentMethod ===
        'Prepayment'
    );
  }

  static hasCurrentElectricityPrepaymentTariff(state) {
    return (
      EnergyQuoteApi.getCurrentQuote(state).electricity &&
      EnergyQuoteApi.getCurrentQuote(state).electricity.tariff.paymentMethod ===
        'Prepayment'
    );
  }

  static getCurrentTariffTab(state) {
    return state.getIn([STORE_NAME, 'currentTab']);
  }

  static getEvInterest(state) {
    return state.getIn([STORE_NAME, 'hasEvInterest']);
  }
}

export default EnergyQuoteApi;
