import { STORE_NAME } from './constants';

export const getAddressId = (state) => {
  return state.getIn([STORE_NAME, 'addressPicker', 'selectedAddress']);
};

export const getPropertyOccupancy = (state) => {
  const isOwner = state.getIn([STORE_NAME, 'isPropertyOwner']);
  const occupancy = { type: isOwner ? 'OWNERSHIP' : 'TENANCY' };
  if (isOwner) {
    occupancy.ownership = {};
  } else {
    occupancy.tenancy = {};
  }
  return occupancy;
};
