import { authenticatedPut } from 'redux/utils/request';

const ROUTE_ELECTRICITY = 'electricity';
const ROUTE_GAS = 'gas';

const putPropertySupply = (data, route) =>
  authenticatedPut(`property/supply/${route}`, data);

export const putApplicationPropertySupplyElectricity = (
  estimatedConsumption,
  estimatedNightConsumption,
  prepayment,
  economy
) => {
  return putPropertySupply(
    {
      ...(estimatedConsumption ? { estimatedConsumption } : {}),
      ...(estimatedNightConsumption ? { estimatedNightConsumption } : {}),
      prepayment,
      economy,
    },
    ROUTE_ELECTRICITY
  );
};

export const putApplicationPropertySupplyGas = (
  estimatedConsumption,
  prepayment
) => {
  return putPropertySupply(
    {
      ...(estimatedConsumption ? { estimatedConsumption } : {}),
      prepayment,
    },
    ROUTE_GAS
  );
};
