import Immutable from 'immutable';
import {
  GET_FEATURE_FLAGS_LOADING,
  GET_FEATURE_FLAGS_SUCCESS,
  GET_FEATURE_FLAGS_FAILURE,
} from './constants';

const initialState = Immutable.fromJS({
  flags: {},
  error: null,
  loading: false,
});

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_FEATURE_FLAGS_LOADING:
      return state.merge({
        loading: true,
      });

    case GET_FEATURE_FLAGS_SUCCESS:
      return state.merge({
        loading: false,
        error: null,
        flags: action.flags,
      });

    case GET_FEATURE_FLAGS_FAILURE:
      return state.merge({
        loading: false,
        error: action.error,
      });

    default:
      return state;
  }
}
