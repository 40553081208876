import axios from 'axios';
import { API_URL } from 'config';

export default function(type, slug) {
  const url = `${API_URL}/content/${type}/${slug}`;
  return axios
    .request({
      url,
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((res) => res.data);
}
