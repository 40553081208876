export const STORE_NAME = 'EntryForm';
export const ENTRY_FORM_UPDATE_VALUE = 'ENTRY_FORM_UPDATE_VALUE';
export const ENTRY_FORM_QUESTIONARE_STEP_SET =
  'ENTRY_FORM_QUESTIONARE_STEP_SET';
export const ENTRY_FORM_QUESTIONARE_STEP_ADD =
  'ENTRY_FORM_QUESTIONARE_STEP_ADD';
export const ENTRY_FORM_QUESTIONARE_STEP_REMOVE =
  'ENTRY_FORM_QUESTIONARE_STEP_REMOVE';
export const ENTRY_FORM_POST_ADDRESS_REQUEST =
  'ENTRY_FORM_POST_ADDRESS_REQUEST';
export const ENTRY_FORM_POST_ADDRESS_LOADING =
  'ENTRY_FORM_POST_ADDRESS_LOADING';
export const ENTRY_FORM_POST_ADDRESS_SUCCESS =
  'ENTRY_FORM_POST_ADDRESS_SUCCESS';
export const ENTRY_FORM_POST_ADDRESS_FAILURE =
  'ENTRY_FORM_POST_ADDRESS_FAILURE';
export const ENTRY_FORM_SET_ADDRESS_EXISTS = 'ENTRY_FORM_SET_ADDRESS_EXISTS';
export const ENTRY_FORM_SET_SMART_ELIGIBLE = 'ENTRY_FORM_SET_SMART_ELIGIBLE';
export const ENTRY_FORM_PUT_FORM_REQUEST = 'ENTRY_FORM_PUT_FORM_REQUEST';
export const ENTRY_FORM_PUT_FORM_SUCCESS = 'ENTRY_FORM_PUT_FORM_SUCCESS';
export const ENTRY_FORM_PUT_FORM_FAILURE = 'ENTRY_FORM_PUT_FORM_FAILURE';
export const ENTRY_FORM_HANDLE_ANALYTICS_PAGE =
  'ENTRY_FORM_HANDLE_ANALYTICS_PAGE';
export const CONTINUE_APPLICATION = 'CONTINUE_APPLICATION';
export const ADDRESS_REGISTERED_EVENT = 'Address already registered to account';
