import { connector } from 'redux/utils/connector';
import { PartnerLoginApi } from 'redux/modules/PartnerLogin/api';
import EntryOrganic from 'pages/EntryOrganic';
import { STORE_NAME } from './constants';
import * as actions from './actions';

export default connector(
  STORE_NAME,
  (state) => ({
    cashBack: PartnerLoginApi.isPartnerLoggedIn(state),
  }),
  {
    getQuote: actions.getQuote,
    setIsPropertyOwner: actions.setIsPropertyOwner,
    getAddressList: actions.getAddressList,
    onAddressExistsDialogContinue: actions.onAddressExistsDialogContinue,
    ...actions.addressPickerActions,
  }
)(EntryOrganic);
