import Immutable from 'immutable';
import {
  REQUEST_FAIL,
  REQUEST_FETCHING,
  REQUEST_SENDING,
  REQUEST_SUCCESS,
} from 'constants/requestStatus';
import {
  ACCEPT_QUOTES_FAILURE,
  ACCEPT_QUOTES_REQUEST,
  ACCEPT_QUOTES_SUCCESS,
  CONFIRM_QUOTE,
  GET_QUOTES_FAILURE,
  GET_QUOTES_REQUEST,
  HIDE_TIL_DIALOG,
  SET_CURRENT_TAB,
  SHOW_TIL_DIALOG,
  TOGGLE_ENERGY_QUOTE_DISCLAIMER_READ,
  SET_OFFERED_QUOTES,
  PRESELECT_SUCCESS,
  EV_INTEREST_CHECKED,
} from './constants';
import { mapCurrentQuote } from './presentation';

const initialState = Immutable.fromJS({
  requestStatus: {
    getQuotes: null,
    acceptQuotes: null,
  },
  original: {},
  current: {},
  offered: [],
  selectedQuotes: [],
  selectedQuoteId: null,
  currentTab: null,
  disclaimerRead: false,
  hasEvInterest: false,
});

export default function EnergyQuoteReducer(state = initialState, action) {
  switch (action.type) {
    case GET_QUOTES_REQUEST:
      return state.mergeDeep({
        requestStatus: {
          getQuotes: REQUEST_FETCHING,
        },
      });
    case GET_QUOTES_FAILURE:
      return state.mergeDeep({
        requestStatus: {
          getQuotes: REQUEST_FAIL,
        },
      });

    case SHOW_TIL_DIALOG:
      return state.merge({
        showDialog: true,
        til: action.tariff,
      });

    case CONFIRM_QUOTE: {
      const current = action.map ? mapCurrentQuote(action.quote) : action.quote;
      return state.merge({
        current,
        requestStatus: {
          getQuotes: REQUEST_SUCCESS,
        },
      });
    }

    case HIDE_TIL_DIALOG:
      return state.mergeDeep({
        showDialog: false,
      });

    case ACCEPT_QUOTES_REQUEST:
      return state.mergeDeep({
        requestStatus: {
          acceptQuotes: REQUEST_SENDING,
        },
        selectedQuoteId: action.selectedQuoteId,
      });

    case ACCEPT_QUOTES_SUCCESS:
      return state.mergeDeep({
        requestStatus: {
          acceptQuotes: REQUEST_SUCCESS,
        },
      });

    case ACCEPT_QUOTES_FAILURE:
      return state.mergeDeep({
        requestStatus: {
          acceptQuotes: REQUEST_FAIL,
        },
      });

    case SET_CURRENT_TAB:
      return state.mergeDeep({
        currentTab: action.currentTab,
      });

    case SET_OFFERED_QUOTES: {
      return state.mergeDeep({
        offered: action.offered,
      });
    }

    case TOGGLE_ENERGY_QUOTE_DISCLAIMER_READ:
      return state.mergeDeep({ disclaimerRead: action.disclaimerRead });

    case PRESELECT_SUCCESS:
      return state.mergeDeep(action.preselectState);

    case EV_INTEREST_CHECKED:
      return state.mergeDeep({ hasEvInterest: action.checked });

    default:
      return state;
  }
}
