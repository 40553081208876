import { authenticatedPut } from 'redux/utils/request';
import mapAddress from 'redux/utils/mapAddressToApiBody';

export default (
  landline,
  landlineIsVirgin,
  occupancy,
  smartMeterAccessible,
  previousOccupancy,
  previousAddress
) => {
  const data = {
    occupancy,
    smartMeterAccessible,
    previousOccupancy,
    previousAddress: mapAddress(previousAddress || {}),
  };

  if (landline !== undefined && landline !== '') {
    data.landline = {
      number: landline,
      isVirginLine: landlineIsVirgin,
    };
  }

  return authenticatedPut('entry_form', data);
};
