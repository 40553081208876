export const STORE_NAME = 'EnergyQuote';
export const GET_QUOTES_REQUEST = 'GET_QUOTES_REQUEST';
export const GET_QUOTES_FAILURE = 'GET_QUOTES_FAILURE';

export const SHOW_TIL_DIALOG = 'SHOW_TIL_DIALOG';
export const HIDE_TIL_DIALOG = 'HIDE_TIL_DIALOG';

export const ACCEPT_QUOTES_REQUEST = 'ACCEPT_QUOTES_REQUEST';
export const ACCEPT_QUOTES_SUCCESS = 'ACCEPT_QUOTES_SUCCESS';
export const ACCEPT_QUOTES_FAILURE = 'ACCEPT_QUOTES_FAILURE';

export const CONFIRM_QUOTE = 'CONFIRM_QUOTE';
export const PRE_PAYMENT_QUOTE_LABEL = 'PREPAYMENT';
export const TARIFF_PRE_PAYMENT = 'PPY';

export const SET_CURRENT_TAB = 'SET_CURRENT_TAB';
export const SET_OFFERED_QUOTES = 'SET_OFFERED_QUOTES';

export const PRESELECT_SUCCESS = 'ENERGY_QUOTE_PRESELECT_SUCCESS';

export const PAYMENT_METHODS = {
  DD: 'by monthly Direct Debit',
  ANNUAL: 'Annually',
};

export const TARIFF = {
  FIXED: 'FIXED',
  VARIABLE: 'VARIABLE',
};

export const TARIFF_TYPES = {
  FIXED: 'Fixed',
  VARIABLE: 'Evergreen Variable',
  PREPAYMENT: 'Prepayment',
};

export const TARIFF_LABELS = {
  VALUE: 'Variable Value',
  GOLD: 'Gold',
  GOLD_LED: 'Gold',
  DOUBLE_GOLD: 'Double Gold',
  DOUBLE_GOLD_LED: 'Double Gold',
  DOUBLE_GOLD_FIXED: 'Double Gold Fixed',
  DOUBLE_GOLD_LED_FIXED: 'Double Gold Fixed',
  GREEN_FIXED: 'Green Fixed',
  GREEN_FIXED_LED: 'Green Fixed',
  PREPAYMENT: 'Prepayment',
};

export const getTariff = (quote) => {
  if (
    quote.electricity &&
    quote.electricity.tariff.name !== TARIFF_TYPES.PREPAYMENT
  )
    return {
      label: quote.electricity.tariff.name,
      type: quote.electricity.tariff.type,
    };

  if (quote.gas && quote.gas.tariff.name !== TARIFF_TYPES.PREPAYMENT)
    return {
      label: quote.gas.tariff.name,
      type: quote.gas.tariff.type,
    };

  return {
    label: TARIFF_LABELS.PREPAYMENT,
    type: TARIFF_TYPES.PREPAYMENT,
  };
};

export const TOGGLE_ENERGY_QUOTE_DISCLAIMER_READ =
  'TOGGLE_ENERGY_QUOTE_DISCLAIMER_READ';

export const FREE_LED = 'FREE_LED';

export const EV_INTEREST_CHECKED = 'EV_INTEREST_CHECKED';
