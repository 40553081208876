import moment from 'moment';

export const fixedDateCopy = (copy) =>
  moment
    .months()
    .reduce(
      (acc, month) => acc.replace(month.toLowerCase(), month),
      copy.toLowerCase()
    );

export function endDateCopyHandler(currentQuote, dateOnly = false) {
  if (currentQuote) {
    if (currentQuote.electricity && !currentQuote.gas) {
      const date = fixedDateCopy(currentQuote.electricity.tariff.endDate);
      if (dateOnly) {
        return date;
      }

      return ` (fixed until ${date})`;
    }

    if (!currentQuote.electricity && currentQuote.gas) {
      const date = fixedDateCopy(currentQuote.gas.tariff.endDate);
      if (dateOnly) {
        return date;
      }
      return ` (fixed until ${date})`;
    }

    if (currentQuote.electricity && currentQuote.gas) {
      const serviceList = [currentQuote.electricity, currentQuote.gas];
      const service = serviceList.find((service) =>
        moment(service.tariff.endDate, 'D MMMM YYYY').isValid()
      );
      if (!service) return '';
      const endDateCopy = service && service.tariff.endDate;

      const date = endDateCopy
        ? fixedDateCopy(endDateCopy)
        : fixedDateCopy(currentQuote.electricity.tariff.endDate);
      if (dateOnly) {
        return date;
      }

      return endDateCopy ? ` (fixed until ${date})` : ` (fixed until ${date})`;
    }
  }

  return '';
}

export default null;
