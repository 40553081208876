import { STORE_NAME } from './constants';

export class EntryFormApi {
  static getFieldValues(state) {
    return state.get(STORE_NAME).toJS().fieldValues;
  }

  static isHomeOwner(state) {
    return (
      state.get(STORE_NAME).toJS().fieldValues.currentAddressOwnThisProperty ===
      'yes'
    );
  }

  static isTenant(state) {
    return (
      state.get(STORE_NAME).toJS().fieldValues.currentAddressOwnThisProperty ===
      'no'
    );
  }

  static metersAccessible(state) {
    const fieldValues = state.get(STORE_NAME).toJS().fieldValues;
    return fieldValues.isFlat !== true || fieldValues.metersAccessible === true;
  }

  static requiresSmartMeter(state) {
    return (
      state.get(STORE_NAME).toJS().fieldValues.requireSmartMeterInstalled ===
      true
    );
  }

  static getEac(state) {
    return state.getIn([STORE_NAME, 'eac']).toJS();
  }

  static getSmartEligibility(state) {
    return state.getIn([STORE_NAME, 'smartEligible']);
  }

  static supplyAddress(state) {
    return state.get(STORE_NAME).toJS().fieldValues.currentAddressSelected;
  }

  static isValidEntryForm(state) {
    return state.get(STORE_NAME).toJS().isValidEntryForm;
  }

  static getCurrentlySelectedAddress(state) {
    return state.getIn([STORE_NAME, 'fieldValues', 'currentAddressSelected']);
  }
}
