import { authenticatedPost } from 'redux/utils/request';

export const QUOTE_TYPE_ELECTRICITY = 'electricity';
export const QUOTE_TYPE_GAS = 'gas';
export const QUOTE_TYPE_DUAL = 'dual';

export default (type, paymentPlan, selectedServices, quoteType) => {
  const tariffType = quoteType === 'variable' ? '/?tariffType=variable' : '';
  const data = { paymentPlan, selectedServices };

  return authenticatedPost(`quotes/${type}${tariffType}`, data);
};
