import Immutable from 'immutable';
import mergeReducers from 'redux/utils/mergeReducers';
import createAddressPickerReducer, {
  initialState as addressPickerState,
} from 'redux/modules/AddressPickerNew/reducer';
import * as types from './types';

const initialState = Immutable.fromJS({
  getQuoteLoading: false,
  isPropertyOwner: null,
  addressExistsDialogOpen: false,
  ...addressPickerState,
});

const reducer = function(state = initialState, action) {
  switch (action.type) {
    case types.LANDING_GET_QUOTE_REQUEST:
      return state.set('getQuoteLoading', true);
    case types.LANDING_GET_QUOTE_SUCCESS:
      return state.set('getQuoteLoading', false);
    case types.LANDING_GET_QUOTE_FAILURE:
      return state.set('getQuoteLoading', false);
    case types.LANDING_SET_PROPERTY_OWNER:
      return state.set('isPropertyOwner', action.isPropertyOwner);
    case types.LANDING_GET_ADDRESS_LIST_REQUEST:
      return state.mergeDeep({
        addressPicker: {
          loading: true,
          postcode: action.postcode,
          selectedAddress: null,
        },
      });
    case types.LANDING_GET_ADDRESS_LIST_SUCCESS:
      return state.mergeDeep({
        addressPicker: {
          loading: false,
          addressList: action.addresses,
          selectedAddress:
            action.addresses && action.addresses.length > 0
              ? action.addresses[0].uuid
              : null,
        },
      });
    case types.LANDING_GET_ADDRESS_LIST_FAILURE:
      return state.mergeDeep({
        addressPicker: {
          loading: false,
          addressList: null,
          selectedAddress: null,
        },
      });
    case types.SET_ADDRESS_EXISTS_DIALOG_OPEN:
      return state.merge({
        addressExistsDialogOpen: true,
      });
    case types.ON_ADDRESS_EXISTS_DIALOG_CONTINUE:
      return state.merge({
        addressExistsDialogOpen: false,
      });
    default:
      return state;
  }
};

const addressPickerReducer = createAddressPickerReducer('LANDING');

export default mergeReducers(initialState, reducer, addressPickerReducer);
