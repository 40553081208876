import { STORE_NAME, VARIANT_PREFIX } from './constants';

export class FeatureFlagsApi {
  static getFlag(state, key) {
    return state.getIn([STORE_NAME, 'flags', key]);
  }

  static getFlagSelector(key) {
    return (state) => state.getIn([STORE_NAME, 'flags', key]);
  }

  static getFlags(state) {
    return state.getIn([STORE_NAME, 'flags']).toJS();
  }

  static getVariantFlags(state) {
    const flags = state.getIn([STORE_NAME, 'flags']).toJS();

    return Object.keys(flags)
      .filter((flag) => new RegExp(VARIANT_PREFIX).test(flag))
      .reduce(
        (acc, flag) => ({
          ...acc,
          [flag]: flags[flag],
        }),
        {}
      );
  }
}
