import {
  GET_FEATURE_FLAGS_REQUEST,
  GET_FEATURE_FLAGS_LOADING,
  GET_FEATURE_FLAGS_SUCCESS,
  GET_FEATURE_FLAGS_FAILURE,
} from 'app/redux/modules/FeatureFlags/constants';

export const featureFlagsRequest = (token) => ({
  type: GET_FEATURE_FLAGS_REQUEST,
  token,
});

export const featureFlagsLoading = () => ({
  type: GET_FEATURE_FLAGS_LOADING,
});

export const featureFlagsCompleteRequest = (flags) => ({
  type: GET_FEATURE_FLAGS_SUCCESS,
  flags,
});

export const featureFlagsFailure = (error) => ({
  type: GET_FEATURE_FLAGS_FAILURE,
  error,
});
