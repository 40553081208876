import { STORE_NAME, boostVariants, exclusionParams } from './constants';
import { ApplicationApi } from 'redux/modules/App/api';

function getVersion(state) {
  return state.getIn([STORE_NAME, '_version'], 'v1');
}

function getIsExcluded(state, product) {
  const params = ApplicationApi.getExcludeParams(state);
  return params.includes(product);
}

export function getEligibility(state, product) {
  if (getVersion(state) === 'v2') {
    return state.getIn([STORE_NAME, product, 'eligible'], false);
  }

  return state.getIn([STORE_NAME, product], false);
}

export class EligibilityServiceApi {
  static isLoading(state) {
    return state.getIn([STORE_NAME, 'isLoading']);
  }

  static hasError(state) {
    return !!state.getIn([STORE_NAME, 'error']);
  }

  static isEligibleForBoilerHomecover(state) {
    return getEligibility(state, 'boilerInsurance');
  }

  static isEligibleForCBC(state) {
    if (getIsExcluded(state, exclusionParams.CASHBACK)) {
      return false;
    }
    return getEligibility(state, 'cashback');
  }

  static isEligibleForHomeInsurance(state) {
    return getEligibility(state, 'homeInsurance');
  }

  static isEligibleForBillProtector(state) {
    return getEligibility(state, 'billProtector');
  }

  static isEligibleForIncomeProtector(state) {
    return getEligibility(state, 'incomeProtector');
  }

  static canRegisterForSmartMeter(state) {
    return getEligibility(state, 'canRegisterForSmartMeter');
  }

  static isEligibleForBoost(state) {
    const isEligible = getEligibility(state, 'boost');
    const variant = state.getIn(
      [STORE_NAME, 'boost', 'options', 'variant'],
      false
    );

    return isEligible && variant === boostVariants.REFERRAL;
  }

  static isEligibleForFourServiceDiscount(state) {
    return getEligibility(state, 'fourServiceDiscount');
  }

  static isEligibleForOneTouchSwitch(state) {
    return getEligibility(state, 'oneTouchSwitch');
  }
}

export default EligibilityServiceApi;
