import {
  nonAuthenticatedFullPathRequest,
  genericAuthorizedRequest,
  METHOD,
} from 'redux/utils/request';
import { FEATURE_FLAGS_URL } from 'config';

export const getFeatureFlags = (token) =>
  token
    ? genericAuthorizedRequest(METHOD.GET, FEATURE_FLAGS_URL, token)
    : nonAuthenticatedFullPathRequest(METHOD.GET, FEATURE_FLAGS_URL);
