import { mapValues, pick } from 'lodash';
import {
  TARIFF_PRE_PAYMENT,
  PAYMENT_METHODS,
  TARIFF_LABELS,
} from './constants';
import { formatPrice } from 'app/redux/utils/formatter';

const NO_END_DATE = 'There is no end date';

export function formatMoney(moneyObject) {
  let price = moneyObject.currency === 'GBP' ? '£' : '';
  price += (
    moneyObject.value / Math.pow(10, moneyObject.exponent)
  ).toLocaleString();
  return price;
}

const isPrePaymentTariff = (tariff) => tariff.code === TARIFF_PRE_PAYMENT;

const formatUnitRate = (rate) => `${rate.toFixed(3)} pence per kWh`;

export function formatTariff(tariff) {
  const fields = {
    name: (n) => n,
    type: (t) => t,
    unitRate: formatUnitRate,
    nightUnitRate: formatUnitRate,
    standingCharge: (charge) => `${charge.toFixed(3)} pence per day`,
    endDate: (d) => d || NO_END_DATE,
    exitFee: (fee) => fee || 'N/A',
    discounts: (discounts) => discounts || 'N/A',
    supplier: (s) => s,
    supplyType: (s) => s,
    paymentMethod: (p) => p,
  };

  const mapped = mapValues(pick(tariff, Object.keys(fields)), (val, key) =>
    fields[key](val)
  );
  mapped.showDirectDebitInfo = !isPrePaymentTariff(tariff);
  mapped.rawUnitRate = tariff.unitRate;
  if (tariff.nightUnitRate) {
    mapped.rawNightUnitRate = tariff.nightUnitRate;
  }
  return mapped;
}

export const composeQuotePresentation = (quotes) => {
  const result = { quoteIds: [] };
  const quoteFields = {
    assumedConsumptionSavingRate: (saving) => saving || 0,
    rawAnnualConsumption: (consumption) => consumption,
    annualConsumption: (consumption) => `${consumption.toLocaleString()} kWh`,
    originalElectricityConsumption: (consumption) => consumption,
    personalProjection: formatMoney,
    rawPersonalProjection: (projection) => projection,
    budgetPlan: formatMoney,
    tariff: formatTariff,
    paymentMethod: (m) => PAYMENT_METHODS[m],
    id: (id) => id,
    comparison: (comparison) =>
      comparison
        ? {
            ...comparison,
            personalProjection: formatMoney(comparison.personalProjection),
          }
        : null,
  };

  let prePaymentTariffs = 0;

  quotes.map((quote) => {
    const quoteType = quote.type.toLowerCase();
    const subset = {
      ...pick(quote, Object.keys(quoteFields)),
      rawAnnualConsumption: quote.annualConsumption,
      originalElectricityConsumption: quote.originalAnnualConsumption,
      rawPersonalProjection: quote.personalProjection,
    };
    result[quoteType] = mapValues(subset, (val, key) => quoteFields[key](val));
    result.quoteIds.push(subset.id);

    if (isPrePaymentTariff(quote.tariff)) {
      prePaymentTariffs++;
    } else {
      // Overwrite any pre existing tariff/payment details
      // as we'll currently assume all use the same methods/tariffs
      result.tariff = quote.tariff.name;
      result.paymentMethod = quoteFields.paymentMethod(quote.paymentMethod);
      result.tariffType = quote.tariff.type;
    }

    return result;
  });

  result.showTariffPaymentOptions =
    prePaymentTariffs !== Object.keys(quotes).length;

  return result;
};

const mapStdQuote = (quote) => ({
  label: TARIFF_LABELS[quote.label],
  idLabel: quote.label,
  benefits: quote.benefits,
  ...composeQuotePresentation(quote.quotes),
  totalCost: formatPrice(quote.totalCost),
});

export const mapCurrentQuote = (quote) => ({
  ...mapStdQuote(quote),
  currentProducts: quote.productsRequired,
});
