import Immutable from 'immutable';

import {
  TRIGGER_ELIGIBILITY_CHECK_REQUEST,
  TRIGGER_ELIGIBILITY_CHECK_SUCCESS,
  TRIGGER_ELIGIBILITY_CHECK_ERROR,
} from './constants';

const initialState = Immutable.fromJS({
  cashback: false,
  billProtector: false,
  homeInsurance: false,
  canRegisterForSmartMeter: false,
  smartMeter: false,
  boilerInsurance: false,
  isLoading: false,
  error: null,
  _version: null,
});

export default function(state = initialState, action) {
  switch (action.type) {
    case TRIGGER_ELIGIBILITY_CHECK_SUCCESS:
      return state.mergeDeep({
        ...action.eligibility,
        isLoading: false,
        error: null,
      });

    case TRIGGER_ELIGIBILITY_CHECK_REQUEST:
      return state.mergeDeep({ isLoading: true, error: null });

    case TRIGGER_ELIGIBILITY_CHECK_ERROR:
      return state.mergeDeep({ isLoading: false, error: action.error });

    default:
      return state;
  }
}
