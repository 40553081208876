import Immutable from 'immutable';
import { get } from 'lodash';
import { REQUEST_SENDING, REQUEST_SUCCESS } from 'constants/requestStatus';

import isValid from './form.js';
import {
  ENTRY_FORM_UPDATE_VALUE,
  ENTRY_FORM_PUT_FORM_SUCCESS,
  ENTRY_FORM_POST_ADDRESS_REQUEST,
  ENTRY_FORM_POST_ADDRESS_FAILURE,
  ENTRY_FORM_POST_ADDRESS_SUCCESS,
  ENTRY_FORM_PUT_FORM_REQUEST,
  ENTRY_FORM_SET_ADDRESS_EXISTS,
  ENTRY_FORM_QUESTIONARE_STEP_SET,
  ENTRY_FORM_QUESTIONARE_STEP_ADD,
  ENTRY_FORM_QUESTIONARE_STEP_REMOVE,
} from './constants';

import { TOGGLE_ADDRESS_NOT_LISTED } from './../AddressPicker/constants';

const fieldNames = [
  'currentAddressSelected',
  'currentAddressOwnThisProperty',
  'currentAddressMovedInWithin3Months',
  'requireSmartMeterInstalled',
  'currentAddressLandlineNumber',
  'currentAddressIDoNotHaveALandline',
  'currentAddressLandlineIsVirgin',
  'previousAddressOwnThisProperty',
  'previousAddressSelected',
  'isFlat',
];

// create and populate the values object
const fieldValues = {};
for (let f of fieldNames.values()) {
  fieldValues[f] = '';
}

const initialState = Immutable.fromJS({
  applicationId: undefined,
  fieldValues: {
    currentAddressSelected: undefined,
    currentAddressOwnThisProperty: undefined,
    currentAddressMovedInWithin3Months: undefined,
    requireSmartMeterInstalled: true,
    currentAddressLandlineNumber: undefined,
    currentAddressIDoNotHaveALandline: undefined,
    currentAddressLandlineIsVirgin: undefined,
    previousAddressOwnThisProperty: undefined,
    previousAddressSelected: undefined,
    isFlat: undefined,
  },
  questionnaire: {
    steps: 0,
    maxStep: 0,
  },
  isValidEntryForm: false,
  predictedDaffodilEligibility: false,
  status: null,
  addressExists: false,
  eac: {
    electricity: undefined,
    gas: undefined,
  },
  addressLoading: false,
  smartEligible: false,
});

export default function(state = initialState, action) {
  switch (action.type) {
    case ENTRY_FORM_UPDATE_VALUE: {
      const fieldValues = { [action.name]: action.value };
      const newValidationState = state.get('fieldValues').toJS();
      newValidationState[action.name] = action.value;
      return state.mergeDeep({
        fieldValues,
        isValidEntryForm: isValid(newValidationState),
      });
    }

    case ENTRY_FORM_POST_ADDRESS_REQUEST:
      return state.set('addressLoading', true);

    case ENTRY_FORM_POST_ADDRESS_FAILURE:
      return state.set('addressLoading', false);
    case ENTRY_FORM_POST_ADDRESS_SUCCESS:
      return state.mergeDeep({
        addressExists: get(action, 'data.isAlreadyRegistered', false),
        eac: {
          electricity: get(action, 'data.eac.electricity'),
          gas: get(action, 'data.eac.gas'),
        },
        addressLoading: false,
        smartEligible: get(action, 'data.smartEligible', false),
      });

    case ENTRY_FORM_SET_ADDRESS_EXISTS:
      return state.mergeDeep({
        addressExists: action.addressAlreadyRegistered,
      });

    case ENTRY_FORM_PUT_FORM_REQUEST:
      return state.merge({
        status: REQUEST_SENDING,
      });

    case ENTRY_FORM_PUT_FORM_SUCCESS:
      return state.mergeDeep({
        status: REQUEST_SUCCESS,
        predictedDaffodilEligibility: action.data.predictedDaffodilEligibility,
      });

    case TOGGLE_ADDRESS_NOT_LISTED:
      return state.mergeDeep({ addressNotListed: action.addressNotListed });

    case ENTRY_FORM_QUESTIONARE_STEP_SET: {
      return state.mergeDeep({
        questionnaire: {
          steps: action.steps,
        },
      });
    }

    case ENTRY_FORM_QUESTIONARE_STEP_ADD: {
      const { steps, maxStep } = state.get('questionnaire').toJS();
      const newStep = steps + 1;
      return state.mergeDeep({
        questionnaire: {
          steps: newStep,
          maxStep: newStep > maxStep ? newStep : maxStep,
        },
      });
    }

    case ENTRY_FORM_QUESTIONARE_STEP_REMOVE: {
      const { steps } = state.get('questionnaire').toJS();
      return state.mergeDeep({
        questionnaire: {
          steps: steps - 1,
        },
      });
    }

    default:
      return state;
  }
}
