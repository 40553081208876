import {
  GET_CONTENT_PAGE_REQUEST,
  GET_CONTENT_PAGE_SUCCESS,
  GET_CONTENT_PAGE_FAILURE,
  GET_CONTENT_ASSET_COLLECTION_REQUEST,
  GET_CONTENT_ASSET_COLLECTION_SUCCESS,
} from './constants';

export const fetchPageRequest = (slug) => ({
  type: GET_CONTENT_PAGE_REQUEST,
  slug,
});

export const fetchAssetCollectionRequest = (slug) => ({
  type: GET_CONTENT_ASSET_COLLECTION_REQUEST,
  slug,
});

export const fetchPageSuccess = (content) => ({
  type: GET_CONTENT_PAGE_SUCCESS,
  content,
});

export const fetchPageFailure = (slug, error) => ({
  type: GET_CONTENT_PAGE_FAILURE,
  slug,
  error,
});

export const fetchAssetCollectionSuccess = (content) => ({
  type: GET_CONTENT_ASSET_COLLECTION_SUCCESS,
  content,
});
