import keys from 'lodash/keys';

import {
  ENERGY_USE_CUSTOM,
  ELECTRICITY_CONSUMPTION,
  ELECTRICITY_DAY_CONSUMPTION,
  ELECTRICITY_NIGHT_CONSUMPTION,
  GAS_CONSUMPTION,
} from './constants';
import { hasGas, hasElectricity, isEconomy7, isFormComplete } from './helpers';

const PATTERN_CONSUMPTION = /^([1-9])|([1-9]\.[1-9])\d+$/;

const isValidConsumption = (consumption) => {
  return !!PATTERN_CONSUMPTION.test(consumption);
};

const validateCustomUsage = (errors, fields) => {
  if (fields.energyUse === ENERGY_USE_CUSTOM) {
    if (hasGas(fields.energy) && !isValidConsumption(fields[GAS_CONSUMPTION])) {
      errors[GAS_CONSUMPTION] = true;
    }
    if (hasElectricity(fields.energy)) {
      if (isEconomy7(fields.meter)) {
        if (!isValidConsumption(fields[ELECTRICITY_DAY_CONSUMPTION])) {
          errors[ELECTRICITY_DAY_CONSUMPTION] = true;
        }
        if (!isValidConsumption(fields[ELECTRICITY_NIGHT_CONSUMPTION])) {
          errors[ELECTRICITY_NIGHT_CONSUMPTION] = true;
        }
      } else {
        if (!isValidConsumption(fields[ELECTRICITY_CONSUMPTION])) {
          errors[ELECTRICITY_CONSUMPTION] = true;
        }
      }
    }
  }
};

const hasErrors = (errors) => {
  return keys(errors).some((key) => errors[key]);
};

function getFormValidationErrors(fields) {
  let errors = {
    [ELECTRICITY_CONSUMPTION]: false,
    [ELECTRICITY_DAY_CONSUMPTION]: false,
    [ELECTRICITY_NIGHT_CONSUMPTION]: false,
    [GAS_CONSUMPTION]: false,
  };

  const isComplete = isFormComplete(fields);
  if (isFormComplete(fields)) validateCustomUsage(errors, fields);
  return { errors, isComplete };
}

export default function(fields) {
  const { errors, isComplete } = getFormValidationErrors(fields);

  return {
    fieldErrors: errors,
    isValidForm: isComplete && !hasErrors(errors),
  };
}
