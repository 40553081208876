export const STORE_NAME = 'Eligibility';

export const TRIGGER_ELIGIBILITY_CHECK_REQUEST =
  'TRIGGER_ELIGIBILITY_CHECK_REQUEST';
export const TRIGGER_ELIGIBILITY_CHECK_SUCCESS =
  'TRIGGER_ELIGIBILITY_CHECK_SUCCESS';
export const TRIGGER_ELIGIBILITY_CHECK_ERROR =
  'TRIGGER_ELIGIBILITY_CHECK_ERROR';

export const boostVariants = {
  REFERRAL: 'REFERRAL',
};

export const exclusionParams = {
  BOILER: 'boiler',
  BILL_INCOME_PROTECTOR: 'billincomeprotector',
  CASHBACK: 'cashback',
  ORDER_SUMMARY: 'ordersummary',
};
