import { connector } from 'redux/utils/connector';
import EntryFormUI from 'components/modules/LandingPage/EntryForm';
import { STORE_NAME } from './constants';
import { STORE_NAME as ADDRESS_PICKER_STORE } from 'redux/modules/AddressPicker/constants';
import { ServiceSelectionApi } from 'redux/modules/ServiceSelection/api';
import { ApplicationApi } from 'redux/modules/App/api';
import SalesLoginApi from 'redux/modules/SalesLogin/api';

export default connector(STORE_NAME, (state) => ({
  ...state.get(ADDRESS_PICKER_STORE).toJS(),
  lead: SalesLoginApi.getLead(state),
  leadError: SalesLoginApi.getError(state),
  existingApplication:
    ApplicationApi.getCurrentApplication(state) &&
    ServiceSelectionApi.hasSelectedServices(state),
  isLoading: SalesLoginApi.isLoading(state),
  isRemoteSession: ApplicationApi.isRemoteSession(state),
}))(EntryFormUI);
