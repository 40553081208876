import {
  TRIGGER_ELIGIBILITY_CHECK_REQUEST,
  TRIGGER_ELIGIBILITY_CHECK_SUCCESS,
  TRIGGER_ELIGIBILITY_CHECK_ERROR,
} from './constants';

export const triggerEligibilityChecks = () => ({
  type: TRIGGER_ELIGIBILITY_CHECK_REQUEST,
});

export const eligibilityResponse = (eligibility) => ({
  type: TRIGGER_ELIGIBILITY_CHECK_SUCCESS,
  eligibility,
});

export const eligibilityError = (error) => ({
  type: TRIGGER_ELIGIBILITY_CHECK_ERROR,
  error,
});
