import { EnergyProductSelectionApi } from 'redux/modules/Energy/ProductSelectionForm/api';
import EnergyQuoteApi from 'redux/modules/Energy/Quote/api';

const calculateLEDSaving = (ledEnergySaved, unitRate) =>
  (ledEnergySaved * unitRate) / 100;

export const getElectricityFieldsFromState = (state, currentQuote) => {
  if (!currentQuote.electricity) return {};

  const dayConsumption = EnergyProductSelectionApi.getProvidedElectricityDayConsumption(
    state
  );
  const nightConsumption = EnergyProductSelectionApi.getProvidedElectricityNightConsumption(
    state
  );
  const isEconomy = EnergyProductSelectionApi.isEconomy(state);
  const electricityConsumption = EnergyQuoteApi.getOriginalElectricityConsumptionFromQuote(
    state
  );
  let ledMoneySaved;
  let ledEnergySaved;

  const tariff = currentQuote.electricity.tariff;
  if (isEconomy) {
    const pcDay =
      (dayConsumption / ((dayConsumption + nightConsumption) / 100)) * 0.01;
    const pcNight = 1 - pcDay;
    ledMoneySaved = (
      calculateLEDSaving(ledEnergySaved * pcDay, tariff.rawUnitRate) +
      calculateLEDSaving(ledEnergySaved * pcNight, tariff.rawNightUnitRate)
    ).toFixed(2);
  }

  return {
    electricityConsumption: Math.round(electricityConsumption),
    ...(currentQuote.electricity
      ? { ledSavings: { energy: ledEnergySaved, money: ledMoneySaved } }
      : {}),
  };
};
