import Immutable from 'immutable';
import {
  GET_CONTENT_PAGE_REQUEST,
  GET_CONTENT_PAGE_SUCCESS,
  GET_CONTENT_PAGE_FAILURE,
  GET_CONTENT_ASSET_COLLECTION_SUCCESS,
} from './constants';

const initialState = Immutable.fromJS({});

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_CONTENT_PAGE_REQUEST:
      return state.mergeDeep({
        [action.slug]: {
          loading: true,
          error: null,
        },
      });

    case GET_CONTENT_PAGE_SUCCESS: {
      const { slug, body, title } = action.content;
      return state.mergeDeep({
        [slug]: {
          loading: false,
          content: body,
          title,
          error: null,
        },
      });
    }

    case GET_CONTENT_ASSET_COLLECTION_SUCCESS: {
      const { slug, assets } = action.content;
      return state.mergeDeep({
        [slug]: {
          loading: false,
          content: assets,
          error: null,
        },
      });
    }

    case GET_CONTENT_PAGE_FAILURE: {
      const { slug, error } = action;
      return state.mergeDeep({
        [slug]: {
          loading: false,
          content: null,
          error,
        },
      });
    }

    default:
      return state;
  }
}
