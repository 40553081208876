import { call, put, takeLatest } from 'redux-saga/effects';
import { openErrorGlobalDialog } from 'redux/modules/GlobalDialog/actions';
import * as analyticsActions from 'redux/modules/Analytics/actions';
import Sentry from 'app/lib/analytics/sentry';
import ERRORS from 'app/lib/analytics/sentry/errors';
import * as actions from './actions';
import {
  ENTRY_FORM_HANDLE_ANALYTICS_PAGE,
  ENTRY_FORM_POST_ADDRESS_REQUEST,
  ENTRY_FORM_PUT_FORM_REQUEST,
} from './constants';
import postApplicationPropertyAddress from './service/postApplicationPropertyAddress';
import putEntryForm from './service/putEntryForm';
import { routesTrackingMap } from 'app/lib/analytics/TrackPages/trackingMap';

export function* combinedSagas() {
  yield takeLatest(ENTRY_FORM_POST_ADDRESS_REQUEST, handlePostAddress);
  yield takeLatest(ENTRY_FORM_PUT_FORM_REQUEST, handlePutEntryForm);
  yield takeLatest(ENTRY_FORM_HANDLE_ANALYTICS_PAGE, handleAnalyticsPage);
}

export function* handlePostAddress(action) {
  let { address, next, occupancy } = action;
  let filteredAddress = {};
  Object.entries(address).forEach(([key, value]) => {
    if (!value) {
      return;
    }

    filteredAddress = {
      ...filteredAddress,
      [key]: value,
    };
  });

  yield put(actions.postAddressLoading());

  try {
    const response = yield call(
      postApplicationPropertyAddress,
      filteredAddress,
      occupancy
    );
    yield put(actions.postAddressSuccess(response.data));
    next();
    return response.data;
  } catch (error) {
    Sentry.log(error, ERRORS.UPDATE_ENTRY_FORM_ADDRESS);
    yield put(actions.postAddressFailure(error));
    const errorMessage = `We encountered an error while saving your address. ${error}`;
    yield put(openErrorGlobalDialog(errorMessage));
  }
}

function* handlePutEntryForm(action) {
  try {
    const landline = action.landline.replace(/\s+/g, '');
    const response = yield call(
      putEntryForm,
      landline,
      action.landlineIsVirgin,
      action.occupancy,
      action.smartMeterAccessible,
      action.previousOccupancy,
      action.previousAddress
    );
    yield put(actions.putEntryFormSuccess(response));
  } catch (error) {
    Sentry.log(error, ERRORS.UPDATE_ENTRY_FORM);
    yield put(actions.putEntryFormFailure(error));
    yield put(
      openErrorGlobalDialog(
        `We encountered an error while posting your details. ${error}`
      )
    );
  }
}

function* handleAnalyticsPage(action) {
  const page = routesTrackingMap[action.context.path];
  yield put(analyticsActions.trackPage(page));
}
