import Immutable from 'immutable';
import {
  REQUEST_SENDING,
  REQUEST_FAIL,
  REQUEST_SUCCESS,
} from 'constants/requestStatus';
import {
  METER_STANDARD,
  PAYMENT_TYPE_DIRECT_DEBIT,
  ENERGY_USE_MEDIUM,
  PRODUCT_ELECTRICITY_AND_GAS,
  PAYMENT_PLAN_BUDGET,
  ENERGY_PRODUCT_SELECTION_FORM_UPDATE,
  ENERGY_PRODUCT_SELECTION_FORM_VALIDATE,
  POST_APPLICATION_PROPERTY_METER_FAILURE,
  POST_APPLICATION_PROPERTY_METER_REQUEST,
  POST_APPLICATION_PROPERTY_METER_SUCCESS,
  PRESELECT_SUCCESS,
} from './constants';

const initialState = Immutable.fromJS({
  fields: {
    energy: PRODUCT_ELECTRICITY_AND_GAS,
    meter: METER_STANDARD,
    paymentType: PAYMENT_TYPE_DIRECT_DEBIT,
    energyUse: ENERGY_USE_MEDIUM,
    prepaid: null,

    gasConsumption: '',
    electricityConsumption: '',
    electricityDayConsumption: '',
    electricityNightConsumption: '',

    paymentPlan: PAYMENT_PLAN_BUDGET,
  },
  fieldErrors: {},
  isValidForm: false,
  requestStatus: {
    propertyMeter: null,
  },
});

export default function(state = initialState, action) {
  const { fieldErrors, isValidForm } = action;

  switch (action.type) {
    case ENERGY_PRODUCT_SELECTION_FORM_VALIDATE:
      return state.merge({
        fieldErrors,
        isValidForm,
      });

    case ENERGY_PRODUCT_SELECTION_FORM_UPDATE:
      return state.mergeDeep({
        fields: { [action.name]: action.value },
      });

    case POST_APPLICATION_PROPERTY_METER_REQUEST:
      return state.mergeDeep({
        requestStatus: {
          propertyMeter: REQUEST_SENDING,
        },
      });

    case POST_APPLICATION_PROPERTY_METER_SUCCESS:
      return state.mergeDeep({
        requestStatus: {
          propertyMeter: REQUEST_SUCCESS,
        },
      });

    case POST_APPLICATION_PROPERTY_METER_FAILURE:
      return state.mergeDeep({
        requestStatus: {
          propertyMeter: REQUEST_FAIL,
        },
        error: action.error,
      });

    case PRESELECT_SUCCESS:
      return state.mergeDeep(action.preselectState);

    default:
      return state;
  }
}
