import { startAgain } from '../App/actions';

import {
  ENTRY_FORM_UPDATE_VALUE,
  ENTRY_FORM_QUESTIONARE_STEP_SET,
  ENTRY_FORM_QUESTIONARE_STEP_ADD,
  ENTRY_FORM_QUESTIONARE_STEP_REMOVE,
  ENTRY_FORM_POST_ADDRESS_REQUEST,
  ENTRY_FORM_POST_ADDRESS_LOADING,
  ENTRY_FORM_POST_ADDRESS_SUCCESS,
  ENTRY_FORM_POST_ADDRESS_FAILURE,
  ENTRY_FORM_SET_ADDRESS_EXISTS,
  ENTRY_FORM_PUT_FORM_REQUEST,
  ENTRY_FORM_PUT_FORM_FAILURE,
  ENTRY_FORM_PUT_FORM_SUCCESS,
  ENTRY_FORM_HANDLE_ANALYTICS_PAGE,
  CONTINUE_APPLICATION,
} from './constants';

const OWNERSHIP = 'OWNERSHIP';
const TENANCY = 'TENANCY';

export const updateAnalyticsPage = (path, context) => ({
  type: ENTRY_FORM_HANDLE_ANALYTICS_PAGE,
  path,
  context,
});

export const postAddressRequest = (address, next) => ({
  type: ENTRY_FORM_POST_ADDRESS_REQUEST,
  address,
  next,
});
export const postAddressLoading = () => ({
  type: ENTRY_FORM_POST_ADDRESS_LOADING,
});
export const postAddressSuccess = (data) => ({
  type: ENTRY_FORM_POST_ADDRESS_SUCCESS,
  data,
});
export const postAddressFailure = (error) => ({
  type: ENTRY_FORM_POST_ADDRESS_FAILURE,
  error,
});
export const setAddressExists = (isAlreadyRegistered) => ({
  type: ENTRY_FORM_SET_ADDRESS_EXISTS,
  isAlreadyRegistered,
});

export const update = (value, name) => ({
  type: ENTRY_FORM_UPDATE_VALUE,
  name,
  value,
});

export const setStep = (steps) => ({
  type: ENTRY_FORM_QUESTIONARE_STEP_SET,
  steps,
});
export const addStep = () => ({
  type: ENTRY_FORM_QUESTIONARE_STEP_ADD,
});
export const removeStep = () => ({
  type: ENTRY_FORM_QUESTIONARE_STEP_REMOVE,
});

export const existingSessionAnswer = (continueSession) => {
  if (continueSession) {
    return { type: CONTINUE_APPLICATION };
  }

  return startAgain();
};

export const putEntryFormRequest = (
  landline,
  landlineIsVirgin,
  occupancyType,
  occupancyDetails,
  smartMeterAccessible,
  previousOccupancyType,
  previousAddress
) => {
  let occupancy = {};
  let previousOccupancy = null;
  if (occupancyType === 'yes') {
    occupancy = { type: OWNERSHIP, ownership: { recent: occupancyDetails } };
  }

  if (occupancyType === 'no') {
    occupancy = { type: TENANCY, tenancy: {} };
  }

  if (previousOccupancyType === true) {
    previousOccupancy = { type: OWNERSHIP, ownership: { recent: false } };
  }

  if (previousOccupancyType === false) {
    previousOccupancy = { type: TENANCY, tenancy: {} };
  }
  return {
    type: ENTRY_FORM_PUT_FORM_REQUEST,
    landline,
    landlineIsVirgin,
    occupancy,
    smartMeterAccessible,
    previousOccupancy,
    previousAddress,
  };
};

export const putEntryFormFailure = (error) => ({
  type: ENTRY_FORM_PUT_FORM_FAILURE,
  error,
});
export const putEntryFormSuccess = ({ data }) => ({
  type: ENTRY_FORM_PUT_FORM_SUCCESS,
  data,
});
