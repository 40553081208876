/* eslint-disable no-mixed-operators */

import validateGBNumber from '../../utils/validateGBNumber';

export default (val) => {
  const {
    currentAddressSelected,
    currentAddressOwnThisProperty,
    currentAddressLandlineNumber,
    currentAddressIDoNotHaveALandline,
    currentAddressMovedInWithin3Months,
    isFlat,
    metersAccessible,
    previousAddressOwnThisProperty,
    previousAddressSelected,
  } = val;

  const validLandline =
    validateGBNumber(currentAddressLandlineNumber) === true &&
    currentAddressLandlineNumber !== '';

  const validOwnThisProperty =
    currentAddressOwnThisProperty === 'yes' &&
    (currentAddressMovedInWithin3Months === false ||
      (currentAddressMovedInWithin3Months === true &&
        previousAddressOwnThisProperty === false) ||
      (previousAddressOwnThisProperty === true &&
        previousAddressSelected !== ''));

  const validMeterInput =
    metersAccessible === true || metersAccessible === false;

  const validFlatTenant =
    currentAddressOwnThisProperty === 'no' && isFlat && validMeterInput;

  const validHouseTenant = currentAddressOwnThisProperty === 'no' && !isFlat;

  if (
    (currentAddressSelected && currentAddressLandlineNumber && validLandline) ||
    (currentAddressSelected && currentAddressIDoNotHaveALandline === true)
  ) {
    if (validOwnThisProperty || validFlatTenant || validHouseTenant) {
      return true;
    }

    return false;
  }
  return false;
};
