export const STORE_NAME = 'Content';

export const GET_CONTENT_PAGE_REQUEST = 'GET_CONTENT_PAGE_REQUEST';
export const GET_CONTENT_PAGE_SUCCESS = 'GET_CONTENT_PAGE_SUCCESS';
export const GET_CONTENT_PAGE_FAILURE = 'GET_CONTENT_PAGE_FAILURE';
export const GET_CONTENT_ASSET_COLLECTION_REQUEST =
  'GET_CONTENT_ASSET_COLLECTION_REQUEST';
export const GET_CONTENT_ASSET_COLLECTION_SUCCESS =
  'GET_CONTENT_ASSET_COLLECTION_SUCCESS';

// slugs used by app
export const DAFFODIL_TERMS_AND_CONDITIONS = 'daffodil-terms-and-conditions';
export const MOBILE_TARIFF_INFO = 'mobile-tariff-info-ed';
export const CASHBACK_INFO = 'cashback-info-ed';
export const DETAILS_YOUR_HOMEPHONE = 'detailshomephone';
export const DETAILS_YOUR_BROADBAND = 'detailsyourbroadband';
export const DETAILS_YOUR_MOBILE = 'detailsyourmobileed';
export const DETAILS_CASHBACK_CARD = 'detailscashbackcarded';

export const selectors = {
  pages: (store) => store.get(STORE_NAME).toJS(),
};

export const contentfulTypes = {
  ASSET_COLLECTION: 'assetCollection',
  PAGE: 'page',
  TARIFFS: 'tariffs',
};
