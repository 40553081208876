import { takeLatest, call, put } from 'redux-saga/effects';
import getEligibilityService from './service/getEligibility';
import * as actions from './actions';
import { TRIGGER_ELIGIBILITY_CHECK_REQUEST } from './constants';

function* getEligibility() {
  try {
    const eligibility = yield call(getEligibilityService);
    yield put(actions.eligibilityResponse(eligibility.data));
  } catch (error) {
    yield put(actions.eligibilityError(error));
    throw new Error('Unable to retrieve eligibility service');
  }
}

export function* combinedSagas() {
  yield takeLatest(TRIGGER_ELIGIBILITY_CHECK_REQUEST, getEligibility);
}
