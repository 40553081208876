import merge from 'lodash/merge';
import { APP_VERSION } from 'config';
import { takeLatest, call, put, select } from 'redux-saga/effects';
import { PartnerLoginApi } from 'redux/modules/PartnerLogin/api';
import { getFeatureFlags } from 'redux/modules/FeatureFlags/client';
import { openErrorGlobalDialog } from 'redux/modules/GlobalDialog/actions';
import { PARTNER_LOGOUT } from 'redux/modules/PartnerLogin/constants';
import { APP_START_AGAIN } from 'redux/modules/App/constants';
import { getLogger } from 'app/lib/logger';
import Sentry from 'app/lib/analytics/sentry';
import ERRORS from 'app/lib/analytics/sentry/errors';

import {
  featureFlagsRequest,
  featureFlagsLoading,
  featureFlagsCompleteRequest,
  featureFlagsFailure,
} from './actions';
import { GET_FEATURE_FLAGS_REQUEST } from './constants';

const logger = getLogger('[features]', 'info', console);

function* handleFeatureFlagsRequest(action) {
  yield put(featureFlagsLoading());
  try {
    const res = yield call(getFeatureFlags, action.token);
    const data = res ? res.data : {};
    yield put(featureFlagsCompleteRequest(data));
    logger.info(`successfully loaded ${APP_VERSION}`, data);
  } catch (error) {
    Sentry.log(
      error,
      merge(ERRORS.GET_FEATURE_FLAGS, {
        extra: { appVersion: APP_VERSION },
      })
    );
    yield put(featureFlagsFailure(error));
    const errorMessage = `We encountered an error while getting the feature flags. ${error}`;
    yield put(openErrorGlobalDialog(errorMessage));
    logger.error(`issues on loading ${APP_VERSION}`);
  }
}

export function* featureFlagsInit() {
  const token = yield select(PartnerLoginApi.getPartnerToken);
  yield put(featureFlagsRequest(token));
}

export function* combinedSagas() {
  yield takeLatest(
    [GET_FEATURE_FLAGS_REQUEST, PARTNER_LOGOUT, APP_START_AGAIN],
    handleFeatureFlagsRequest
  );
}
