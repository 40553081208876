import moment from 'moment';
export const STORE_NAME = 'FeatureFlags';
export const VARIANT_PREFIX = '^variant.';

export const GET_FEATURE_FLAGS_REQUEST =
  'FeatureFlags/GET_FEATURE_FLAGS_REQUEST';
export const GET_FEATURE_FLAGS_LOADING =
  'FeatureFlags/GET_FEATURE_FLAGS_LOADING';
export const GET_FEATURE_FLAGS_SUCCESS =
  'FeatureFlags/GET_FEATURE_FLAGS_SUCCESS';
export const GET_FEATURE_FLAGS_FAILURE =
  'FeatureFlags/GET_FEATURE_FLAGS_FAILURE';

export const FLAGS = {
  PAUSE_DAFFODIL: 'pause.daffodil',
  ENERGY_BANNER: 'energy.banner',
  PAUSE_NEWLINE_INSTALLATION: 'pause.newline.installation',
  HOMEPHONE_HIGHLIGHTS: 'homephone.highlights',
  REMOTE_SUPPORT: 'remote.support',
  EAC_SALES: 'eac.sales',
  EAC_EVERYONE: 'eac.everyone',
  STATE_RESTORE: 'state.restore',
  NEW_BROADBAND_EERO: 'new.broadband.eero',
  MOBILE_DISABLE_UNLIMITED_FOR_MOBILE_ONLY:
    'mobile.disable.unlimited.for.mobile.only',
  APPLICATION_SAVE_AND_RESTORE: 'application.save.and.restore',
  APPLICATION_SAVE_AND_RESTORE_SALES: 'application.save.and.restore.sales',
  APPLICATION_SAVE_AND_RESTORE_REMOTE: 'application.save.and.restore.remote',
  AMPLIFY_MOBILE: 'amplify.mobile',
  EVERYBODY_SAVES: 'incentive.everybody.saves',
  EVERYBODY_SAVES_BUNDLES_PAGE_PROMO: 'everybody.saves.bundles.page.promo',
  SAVINGS_SUMMARY: 'savings.summary',
  HOMEAUDIT_COLLECTION: 'homeaudit.collection',
  CBC_50_Promo: 'cbc.50.promo',
  HIDE_BYOB_FOR_ALL_ORGANIC: 'hide.byob.for.all.organic',
  HIDE_BYOB_FOR_ORGANIC_TENANTS: 'hide.byob.for.organic.tenants',
  FIXED_LINE_PRICE_CHANGE: moment().isBetween('2021-11-20', '2022-01-01'), // exclusive by default
  BILL_INCOME_PAGE: 'bill.income.page',
  ENERGY_PRICE_ANNOUNCEMENT: 'energy.variable.price.announcement',
  CASHBACK_FOR_ORGANIC: 'cashback.for.organic',
  LANDING_PAGE_NEW: 'landing.page.new',
  DETAILS_PROPERTY_QUESTIONS: 'details.property.questions',
  ENERGY_FIXED_DISABLED: 'energy.fixed.disabled',
  BUNDLE_CONTROL_INSURANCE: 'bundles.control.insurance',
  HEADER_PROGRESS: 'header.progress',
  CREDIT_RISK_PLATFORM_ENABLED: 'credit.risk.platform.enabled',
  ORGANIC_PREPAID_DENIED: 'organic.prepaid.denied',
  ORGANIC_TENANT_ENERGY_DENIED: 'organic.tenant.energy.denied',
  ORGANIC_TENANT_INBOUND_CALL_ENABLED: 'organic.tenant.inbound.call.enabled',
  ENERGY_EPG_ANNOUNCEMENT: 'energy.epg.announcement',
  BOILER_ORGANIC_PRICE_CHANGE: 'insurance.boiler.organic.price.change',
  CTA_STICKY_NAVIGATION_ENABLED: 'cta.sticky.navigation.enabled',
  THANKYOU_PAGE_NEW: 'thankyou.new',
  ORGANIC_PAGE_NEW_VARIANT: 'organic.page.new.variant',
  AMPLIFY_PRIZE_DRAW: 'amplify.prize.draw',
  INSURANCE_BOILER_FLASH_SALE_END: 'insurance.boiler.flash.sale.end',
  INSURANCE_BOILER_FLASH_SALE_START: 'insurance.boiler.flash.sale.start',
  NEW_MOBILE_TARIFFS: 'new.mobile.tariffs',
  INSURANCE_HOME_INSURANCE_ENABLED: 'insurance.hi.enabled',
  INSURANCE_NEW_BHC_PRICE: 'insurance.bhc.new.price',
  INSURANCE_PROTECTORS_DISABLED: 'protectors.disabled',
};

export const VARIANTS = {};
