import isEmpty from 'lodash/isEmpty';

import {
  METER_ECONOMY7,
  PAYMENT_TYPE_PREPAID,
  PRODUCT_GAS,
  PRODUCT_ELECTRICITY,
  PRODUCT_ELECTRICITY_AND_GAS,
  GAS_USE_LOW_VALUE,
  ENERGY_USE_LOW,
  ENERGY_USE_LOW_VALUE,
  ENERGY_USE_LOW_DAY_VALUE,
  ENERGY_USE_LOW_NIGHT_VALUE,
  GAS_USE_MEDIUM_VALUE,
  ENERGY_USE_MEDIUM,
  ENERGY_USE_MEDIUM_VALUE,
  ENERGY_USE_MEDIUM_DAY_VALUE,
  ENERGY_USE_MEDIUM_NIGHT_VALUE,
  GAS_USE_HIGH_VALUE,
  ENERGY_USE_HIGH,
  ENERGY_USE_HIGH_VALUE,
  ENERGY_USE_HIGH_DAY_VALUE,
  ENERGY_USE_HIGH_NIGHT_VALUE,
  ENERGY_USE_CUSTOM,
  PREPAID_GAS,
  PREPAID_ELECTRICITY,
  PREPAID_ELECTRICITY_AND_GAS,
  ENERGY_USE_EAC,
} from 'redux/modules/Energy/ProductSelectionForm/constants';

export const hasGas = (energyType) =>
  energyType === PRODUCT_GAS || energyType === PRODUCT_ELECTRICITY_AND_GAS;
export const isGas = (energyType) => energyType === PRODUCT_GAS;
export const hasElectricity = (energyType) =>
  energyType === PRODUCT_ELECTRICITY ||
  energyType === PRODUCT_ELECTRICITY_AND_GAS;
export const isElectricity = (energyType) => energyType === PRODUCT_ELECTRICITY;
export const isElectricityAndGas = (energyType) =>
  energyType === PRODUCT_ELECTRICITY_AND_GAS;
export const isEconomy7 = (meterType) => meterType === METER_ECONOMY7;
export const isPrepaid = (paymentType) => paymentType === PAYMENT_TYPE_PREPAID;
export const isElectricityAndGasPrepaid = (prepaidType) =>
  prepaidType === PREPAID_ELECTRICITY_AND_GAS;
export const isElectricityPrepaid = (prepaidType) =>
  prepaidType === PREPAID_ELECTRICITY;
export const isGasPrepaid = (prepaidType) => prepaidType === PREPAID_GAS;

export const format = (value) => {
  // transform e.g. 1800 to 1,800
  if (!value) return '';
  return Number(value).toLocaleString();
};

export const formatEconomy7 = (dayConsumption, nightConstumption) =>
  `${format(
    dayConsumption + nightConstumption
  )}  kWh of electricity per year (${format(dayConsumption)} day / ${format(
    nightConstumption
  )} night)`;

export const canShowEac = (eacFeatureFlag, energy, { electricity, gas }) => {
  if (!eacFeatureFlag) return false;

  if (isElectricity(energy) && !isEmpty(electricity)) return true;
  if (isGas(energy) && !isEmpty(gas)) return true;
  if (isElectricityAndGas(energy) && !isEmpty(electricity) && !isEmpty(gas))
    return true;

  return false;
};

export const isFormComplete = ({
  energy,
  paymentType,
  prepaid,
  paymentPlan,
}) => {
  const paymentSelectorEnabled = shouldShowPayment(
    energy,
    paymentType,
    prepaid
  );
  if (paymentSelectorEnabled && isEmpty(paymentPlan)) return false;
  return true;
};

export const shouldShowPayment = (energyType, paymentType, prepaidType) => {
  if (paymentType !== PAYMENT_TYPE_PREPAID) return true;
  switch (energyType) {
    case PRODUCT_ELECTRICITY_AND_GAS:
      return !isElectricityAndGasPrepaid(prepaidType);
    case PRODUCT_ELECTRICITY:
      return !isElectricityPrepaid(prepaidType);
    case PRODUCT_GAS:
      return !isGasPrepaid(prepaidType);
    default:
      return true;
  }
};

export const parseFields = (
  {
    energy,
    meter,
    paymentType,
    prepaid,
    energyUse,
    gasConsumption,
    electricityConsumption,
    electricityDayConsumption,
    electricityNightConsumption,
  },
  eacValues
) => {
  const hasEconomy7 = isEconomy7(meter);
  let mappedFields = {};

  switch (energy) {
    case PRODUCT_ELECTRICITY_AND_GAS:
      mappedFields.gas = {};
      mappedFields.electricity = {};
      if (isPrepaid(paymentType)) {
        if (isElectricityAndGasPrepaid(prepaid)) {
          mappedFields.gas.prepayment = true;
          mappedFields.electricity.prepayment = true;
        }
        if (isElectricityPrepaid(prepaid)) {
          mappedFields.gas.prepayment = false;
          mappedFields.electricity.prepayment = true;
        }
        if (isGasPrepaid(prepaid)) {
          mappedFields.gas.prepayment = true;
          mappedFields.electricity.prepayment = false;
        }
      }
      break;

    case PRODUCT_ELECTRICITY:
      mappedFields.electricity = {};
      if (isPrepaid(paymentType)) mappedFields.electricity.prepayment = true;
      break;

    case PRODUCT_GAS:
      mappedFields.gas = {};
      if (isPrepaid(paymentType)) mappedFields.gas.prepayment = true;
      break;
  }

  switch (energyUse) {
    case ENERGY_USE_LOW:
      if (mappedFields.gas) mappedFields.gas.consumption = GAS_USE_LOW_VALUE;
      if (mappedFields.electricity) {
        mappedFields.electricity.economy = hasEconomy7;
        if (hasEconomy7) {
          mappedFields.electricity.consumption = ENERGY_USE_LOW_DAY_VALUE;
          mappedFields.electricity.nightConsumption = ENERGY_USE_LOW_NIGHT_VALUE;
        } else {
          mappedFields.electricity.consumption = ENERGY_USE_LOW_VALUE;
        }
      }
      break;

    case ENERGY_USE_MEDIUM:
      if (mappedFields.gas) mappedFields.gas.consumption = GAS_USE_MEDIUM_VALUE;
      if (mappedFields.electricity) {
        mappedFields.electricity.economy = hasEconomy7;
        if (hasEconomy7) {
          mappedFields.electricity.consumption = ENERGY_USE_MEDIUM_DAY_VALUE;
          mappedFields.electricity.nightConsumption = ENERGY_USE_MEDIUM_NIGHT_VALUE;
        } else {
          mappedFields.electricity.consumption = ENERGY_USE_MEDIUM_VALUE;
        }
      }
      break;

    case ENERGY_USE_HIGH:
      if (mappedFields.gas) mappedFields.gas.consumption = GAS_USE_HIGH_VALUE;
      if (mappedFields.electricity) {
        mappedFields.electricity.economy = hasEconomy7;
        if (hasEconomy7) {
          mappedFields.electricity.consumption = ENERGY_USE_HIGH_DAY_VALUE;
          mappedFields.electricity.nightConsumption = ENERGY_USE_HIGH_NIGHT_VALUE;
        } else {
          mappedFields.electricity.consumption = ENERGY_USE_HIGH_VALUE;
        }
      }
      break;

    case ENERGY_USE_CUSTOM:
      if (mappedFields.gas)
        mappedFields.gas.consumption = parseInt(gasConsumption);
      if (mappedFields.electricity) {
        mappedFields.electricity.economy = hasEconomy7;
        if (hasEconomy7) {
          mappedFields.electricity.consumption = parseInt(
            electricityDayConsumption
          );
          mappedFields.electricity.nightConsumption = parseInt(
            electricityNightConsumption
          );
        } else {
          mappedFields.electricity.consumption = parseInt(
            electricityConsumption
          );
        }
      }
      break;

    case ENERGY_USE_EAC:
      if (mappedFields.gas) mappedFields.gas.consumption = eacValues.gas.total;
      if (mappedFields.electricity) {
        mappedFields.electricity.economy = hasEconomy7;
        if (hasEconomy7) {
          mappedFields.electricity.consumption = eacValues.electricity.day;
          mappedFields.electricity.nightConsumption =
            eacValues.electricity.night;
        } else {
          mappedFields.electricity.consumption = eacValues.electricity.total;
        }
      }
      break;
  }

  return {
    product: energy,
    ...mappedFields,
  };
};
