export const YOUR_ADDRESS_PATH = {
  name: 'Your address details',
  path: '/home/youraddress',
};
export const ADDRESS_GET_STARTED_PATH = {
  name: 'Your address details',
  path: '/home/youraddress/getstarted',
};
export const LANDLINE_PATH = {
  name: 'Your landline details',
  path: '/home/landline',
};
export const PROPERTY_PATH = {
  name: 'Your property details',
  path: '/home/property',
};
export const ENERGY_PATH = { name: 'Your meter details', path: '/home/meters' };
